import classNames from 'classnames';
import { Calendar } from 'react-date-range';
import dayjs from 'dayjs';
import { useAppContext } from 'providers/AppProvider';
import { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form
} from 'react-bootstrap';

export interface Props {
  date: Date;
  setDate: (date: Date) => void;
  label: string;
  maxDate: Date;
  minDate?: Date;
}

export const TransactionsDatePicker = (props: Props) => {
  const {
    config: { theme }
  } = useAppContext();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        visible &&
        !(event.target as HTMLElement)?.closest('.dropdown-menu')
      ) {
        setVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible]);

  const handleOnToggle = () => {
    setVisible(!visible);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handlePick = (pickedDate: Date) => {
    props.setDate(pickedDate);
    setVisible(false);
  };

  const formatDate = dayjs(props.date).format('DD.MM.YYYY');

  return (
    <Dropdown show={visible} onToggle={handleOnToggle} autoClose={false}>
      <DropdownToggle className="dropdown-caret-none bg-transparent py-0 px-0">
        <Form.Group
          style={{ background: 'none' }}
          className={`text-start border-0 ${classNames('position-relative')}`}
        >
          <Form.Label
            className="position-absolute"
            style={{ background: 'none', top: '-1.2em' }}
            htmlFor="dropdown"
          >
            {props.label}
          </Form.Label>
          <Form.Control
            value={`${formatDate}`}
            readOnly
            id="dropdown"
            className="dropdown-control"
          />
        </Form.Group>
      </DropdownToggle>
      <DropdownMenu className={`${theme == 'dark' && `drop-down-dark`}`}>
        {visible && (
          <div className="d-flex flex-column">
            <Calendar
              date={new Date(props.date)}
              onChange={date => handlePick(date)}
              maxDate={new Date(props.maxDate)}
              minDate={props.minDate && new Date(props.minDate)}
            />
            <div className="d-flex justify-content-between rdrDateRangePickerButtons">
              <Button onClick={handleCancel} className="dp-btn-cancel">
                Отменить
              </Button>
            </div>
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
