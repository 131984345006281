import ScrollSpy from 'components/base/ScrollSpy';
import { Nav } from 'react-bootstrap';

interface Props {
  value: string;
  setValue: (value: string) => void;
}

const LeadDetailsNavbar = ({ value, setValue }: Props) => {
  return (
    <div className="pt-2 pb-2 col-xs-1 z-5">
      <Nav className="nav-underline justify-content-center">
        <Nav.Item onClick={() => setValue('iin')}>
          <ScrollSpy.NavLink
            className={`${value === 'iin' ? 'active' : ''} pt-1 px-2`}
            href="#"
          >
            БИН/ИИН
          </ScrollSpy.NavLink>
        </Nav.Item>
        <Nav.Item onClick={() => setValue('email')}>
          <ScrollSpy.NavLink
            className={`${value === 'email' ? 'active' : ''} pt-1 px-2`}
            href="#"
          >
            Email
          </ScrollSpy.NavLink>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default LeadDetailsNavbar;
