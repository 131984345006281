import { format, getDate, getMonth } from 'date-fns';
import { SearchParamsKey } from 'enums';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { ru } from 'date-fns/locale';
dayjs.locale('ru');

/**
 * @param path url path
 * @returns last url segment
 * @example
 * getLastPathName('authorization/sign-in') // return 'sign-in'
 * getLastPathName('users/edit/add-new-user') // return 'add-new-user'
 */
export const getLastPathName = (path: string) => {
  const startIndex = path.lastIndexOf('/')!;
  return path.slice(startIndex + 1);
};

/**
 * @param sec number
 * @param hideSeconds if true, hide seconds
 * @returns string
 * @example
 * formatSecondsToHHMMSS(50_000) // return "13:53:20"
 * formatSecondsToHHMMSS(216_000) // return "60:00:00"
 *
 * formatSecondsToHHMMSS(50_000, true) // return "13:53"
 * formatSecondsToHHMMSS(216_000) // return "60:00"
 */
export const formatSecondsToHHMMSS = (sec: number, hideHours?: boolean) => {
  let hours: string | number = Math.floor(sec / 3600);
  let minutes: string | number = Math.floor((sec - hours * 3600) / 60);
  let seconds: string | number = sec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  const time = hours + ':' + minutes + ':' + seconds;
  const timeWithoutHours = minutes + ':' + seconds;

  return hideHours ? timeWithoutHours : time;
};

export const formatMMSSToSeconds = (time: string) => {
  const [minutes, seconds] = time.split(':');
  return parseInt(minutes) * 60 + parseInt(seconds);
};

/**
 *
 * @returns concat object
 * @example
 * const concat = getQueryConcatenator()
 *
 * concat.set('page', '2')
 * concat.set('search', 'test')
 *
 * concat.toString() // return "?page=2&search=test"
 */
export const getQueryConcatenator = () => {
  const concat = {
    // this is private property, don't acsess property query like "concat._query", use "concat.toString()" instead
    _query: '?',

    // private property
    _concat(key: SearchParamsKey, value: string) {
      return this._query.concat(
        this._query === '?' ? '' : '&',
        `${key}=${value}`
      );
    },

    set(key: SearchParamsKey, value: string) {
      this._query = this._concat(key, value);
      return this;
    },

    setArray(key: SearchParamsKey, arr: string[]) {
      const queryParams = arr.reduce(
        (acc: string, item, index) =>
          (acc = acc.concat(index !== 0 ? '&' : '', key, `=${item}`)),
        ''
      );

      this._query = this._concat(key, queryParams);

      return this;
    },

    toString() {
      return concat._query;
    }
  };

  return concat;
};

/**
 * @param date date
 * @returns formatted string
 * @example
 * formatDate(new Date()) // return Июн 4, 12:00
 */
export const formatDate = (date: Date) => {
  const day = getDate(date);
  // const day = format(date, 'd', { locale: ru });
  const longTime = format(date, 'HH:mm', { locale: ru });
  let month = '';
  switch (getMonth(date)) {
    case 0:
      month = 'Янв';
      break;
    case 1:
      month = 'Фев';
      break;
    case 2:
      month = 'Мар';
      break;
    case 3:
      month = 'Апр';
      break;
    case 4:
      month = 'Май';
      break;
    case 5:
      month = 'Июн';
      break;
    case 6:
      month = 'Июл';
      break;
    case 7:
      month = 'Авг';
      break;
    case 8:
      month = 'Сен';
      break;
    case 9:
      month = 'Окт';
      break;
    case 10:
      month = 'Ноя';
      break;
    case 11:
      month = 'Дек';
      break;
    default:
      break;
  }

  const str = `${month} ${day}, ${longTime}`;
  return str;
};

/**
 *
 * @param value number
 * @returns string with space
 * @example
 * separateThousands(22500) // return "22 500"
 */
export const separateThousands = (value: number) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const DateFormatter = (dateString: string) => {
  const formattedDate = dayjs(dateString).format('D MMMM YYYY, HH:mm');

  return formattedDate;
};
