import { AxiosResponse } from 'axios';
import { api } from 'config/axios';
// import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export type OrderDetailResponseType = {
  message: string;
  error_code: number;
  data: {
    bnplId: number;
    amount: number;
    orderNumber: string;
    status: number | null;
    date: string;
    refundDate: string;
    paymentCount: number;
    type: string;
    buyer: string;
    seller: string;
    manager: string;
  };
};

export const getOrderDetailsAsync = async (
  bnpdId: number,
  navigate: ReturnType<typeof useNavigate>
) => {
  const URL = `https://guard.paspay.kz/bnpl/details/${bnpdId}`;

  try {
    const res: AxiosResponse<OrderDetailResponseType> = await api.get(URL);
    return res;
  } catch (err) {
    const error = err as Error;
    if (error.message === 'unauthorized') {
      navigate('/auth/sign-in');
    }
  }
};
