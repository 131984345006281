import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useState, useEffect, useRef } from 'react';
import { CreateInstallmentBill } from './modals/CreateInstallmentBill';
import { ScaneQrcode } from './modals/ScaneQrCode';
import useCreateBillStore from './store/store';
import { RejectModal } from './modals/RejectModal';
import { CheckingClientModal } from './modals/CheckingClientModal';
import { ConfirmCodeModal } from './modals/ConfirmCodeModal';
import { GiveOrderModal } from './modals/GiveOrderModal';
import { ShowPhotoModal } from './modals/ShowPhotoModal';
import { UnexpectedErrorModal } from './modals/UnexpectedErrorModal';

const CreateBill = () => {
  const [bill, setBill] = useState<{
    billSum: number;
    orderNumber: string;
    qrCode: string;
    url: string;
  } | null>(null);
  const [showCreateBillModal, setShowCreateBillModal] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showCheckingClientModal, setShowCheckingClientModal] = useState(false);
  const [showGiveOrderModal, setShowGiveOrderModal] = useState(false);
  const [showConfirmCodeModal, setShowConfirmCodeModal] = useState(false);
  const [code, setCode] = useState('');
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const ws = useRef<WebSocket>(null);

  const openConnection = useCreateBillStore(state => state.openConnection);
  const tempBnplIdFromStore = useCreateBillStore(state => state.tempBnplId);
  const dataFromConfirmModal = useCreateBillStore(
    state => state.dataFromConfirmModal
  );
  const setOpenConnection = useCreateBillStore(
    state => state.setOpenConnection
  );

  const handleSuccess = (code: {
    orderNumber: string;
    amount: number;
    qrBase64: string;
    bnplUrl: string;
  }) => {
    setBill({
      orderNumber: code.orderNumber,
      billSum: code.amount,
      qrCode: code.qrBase64,
      url: code.bnplUrl
    });
    setShowQrCodeModal(true);
    setShowCreateBillModal(false);
  };

  const closeWSConnectFromModal = () => {
    // disconnect();
    closeConnectionWS();
  };

  useEffect(() => {
    if (!openConnection) {
      closeConnectionWS();
    }
    openConnection && openConnectionWS();
    console.log(dataFromConfirmModal);
  }, [openConnection]);

  useEffect(() => {
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const openConnectionWS = () => {
    //@ts-expect-error str
    ws.current = new WebSocket(
      `wss://bnpl.paspay.kz/issuance-ws?clientId=${tempBnplIdFromStore}`
    );
    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
      const message = JSON.stringify({
        bnpl_id: tempBnplIdFromStore
      });
      //@ts-expect-error str
      ws.current.send(message);
      timeoutRef.current = setInterval(() => {
        ws.current?.send(
          JSON.stringify({
            bnpl_id: tempBnplIdFromStore
          })
        );
      }, 30000);
    };
    ws.current.onmessage = event => {
      console.log('Message received: ', event.data);
      const data = JSON.parse(event.data);
      if (data.error_code === 0) {
        switch (data.status_id) {
          case 4:
            setShowQrCodeModal(false);
            setShowCheckingClientModal(true);
            break;
          case 5:
            setShowCheckingClientModal(false);
            setShowConfirmCodeModal(true);
            break;
          case 6:
            setShowConfirmCodeModal(false);
            setShowGiveOrderModal(true);
            break;
          case 1:
            closeConnectionWS();
            break;
          case 2:
            closeConnectionWS();
            setShowQrCodeModal(false);
            setShowCheckingClientModal(false);
            setShowConfirmCodeModal(false);
            setShowGiveOrderModal(false);
            setShowRejectModal(true);
            break;
          default:
            break;
        }
      } else if (data.error_code != 0 && data.status_id === 7) {
        closeConnectionWS();
        setShowQrCodeModal(false);
        setShowCheckingClientModal(false);
        setShowConfirmCodeModal(false);
        setShowGiveOrderModal(false);
        setShowRejectModal(true);
      }
    };
    ws.current.onclose = event => {
      console.log('WebSocket connection closed');
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
        timeoutRef.current = null;
      }
      if (event.wasClean) {
        console.log('Clear closed WS...');
      } else {
        console.log('The connection was interrupted for a reason...');
        console.log('Event_code...', event.code);
        setShowQrCodeModal(false);
        setShowCheckingClientModal(false);
        setShowConfirmCodeModal(false);
        setShowGiveOrderModal(false);
        setShowErrorModal(true);
      }
    };
    ws.current.onerror = error => {
      console.error('WebSocket error: ', error);
    };
  };

  const closeConnectionWS = () => {
    if (ws.current) {
      ws.current.close();
      setOpenConnection(false);
    }
  };

  return (
    <>
      <CreateInstallmentBill
        show={showCreateBillModal}
        onHide={() => setShowCreateBillModal(false)}
        onSuccessGenerateCode={handleSuccess}
        onFailGenerateCode={() => {}}
      />
      <ScaneQrcode
        bill={bill ?? { orderNumber: '', billSum: 0, qrCode: '', url: '' }}
        show={showQrCodeModal}
        onHide={() => setShowQrCodeModal(false)}
        closeWSConnection={closeWSConnectFromModal}
      />
      <CheckingClientModal
        time={'00:00'}
        show={showCheckingClientModal}
        onHide={() => setShowCheckingClientModal(false)}
        closeWSConnection={closeWSConnectFromModal}
      />

      <ConfirmCodeModal
        show={showConfirmCodeModal}
        onHide={() => setShowConfirmCodeModal(false)}
        onSuccessGenerateCodeConfirm={v => {
          setCode(v);
          setShowGiveOrderModal(true);
          setShowConfirmCodeModal(false);
        }}
        onFailGenerateCode={() => {}}
        closeWSConnection={closeWSConnectFromModal}
      />

      <GiveOrderModal
        dataFromConfirmModal={dataFromConfirmModal!}
        show={showGiveOrderModal}
        code={code}
        onHide={() => {
          setCode('');
          setShowGiveOrderModal(false);
        }}
        onGiveOrderBtnClick={() => {}}
        onWatchPhotoBtnClick={() => setShowPhotoModal(true)}
        closeWSConnection={closeWSConnectFromModal}
      />

      <ShowPhotoModal
        show={showPhotoModal}
        onHide={() => {
          setShowPhotoModal(false);
        }}
      />

      <RejectModal
        show={showRejectModal}
        onHide={() => {
          setShowRejectModal(false);
        }}
      />
      <UnexpectedErrorModal
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
        }}
      />
      <Button
        variant="primary"
        className="btn-primary"
        startIcon={<FontAwesomeIcon icon={faPlus} className="me-2" />}
        style={{ backgroundColor: '#F7661C' }}
        onClick={() => setShowCreateBillModal(true)}
      >
        Создать счет на рассрочку
      </Button>
    </>
  );
};

export default CreateBill;
