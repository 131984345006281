import { useState } from 'react';
import { OrderDetailResponseType, getOrderDetailsAsync } from '../api';
import { OrderDetailModal } from './components/detail-modal/order-detail-modal';
import { ReturnDetailModal } from './components/return-detail-modal/return-detail-modal';
import { OrdersTable } from './components/table';
import { OrderDetail } from './types';
import { RejectModal } from './components/reject-modal/reject-modal';
import { SuccessModal } from './components/success-modal/success-modal';
import { MakeReturnModal } from './components/make-return-modal/make-return-modal';
import { OrderStatus } from '../enums';
import { CheckingClientModal } from './components/checking-client-modal/checking-client-modal';
import { ConfirmCodeModal } from './components/confirm-code-modal/confirm-code-modal';
import { GiveOrderModal } from './components/give-order-modal/give-order-modal';
import { EmptyResponseModal } from './components/empty-response-modal/empty-response-modal';
import { ShowPhotoModal } from './components/show-photo-modal/show-photo-modal';
import useOrdersStore from '../store/store';
// import useSocket from 'hooks/useSocket';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { OrderType } from './components/table/types';
// import useNavigateUser from 'hooks/useNavigateUser';
// import { useSignOut } from 'hooks/useSignOut';

const defaultOrderDetail: OrderDetail = {
  orderId: 0,
  orderNumber: '',
  amount: 0,
  operationDate: '',
  refundDate: '',
  period: 0,
  paymentType: '',
  buyer: '',
  seller: '',
  manager: ''
};

const mapResponse = (res: OrderDetailResponseType['data']): OrderDetail => ({
  orderId: res.bnplId,
  orderNumber: res.orderNumber,
  amount: res.amount,
  operationDate: res.date,
  period: res.paymentCount,
  paymentType: res.type,
  buyer: res.buyer,
  refundDate: res.refundDate,
  seller: res.seller,
  manager: res.manager
});

const OrdersList = () => {
  const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showEmptyResponseModal, setEmptyResponseModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [showMakeReturnModal, setShowMakeReturnModal] = useState(false);
  const [showCheckingClientModal, setShowCheckingClientModal] = useState(false);
  const [showConfirmCodeModal, setShowConfirmCodeModal] = useState(false);
  const [showGiveOrderModal, setShowGiveOrderModal] = useState(false);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [code, setCode] = useState('');
  const [order, setOrder] = useState<OrderDetail | null>(null);
  const [messageFromResponse, setMessageFromResponse] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<{
    orderId: number;
    status: OrderStatus;
  } | null>(null);
  const { ordersList } = useLoaderData() as {
    totalItems: number;
    totalPages: number;
    ordersList: OrderType[];
  };

  const navigate = useNavigate();

  const dataFromConfirmModal = useOrdersStore(
    state => state.dataFromConfirmModal
  );

  const handleDataUpdate = (updatedData: OrderType[]) => {
    if (selectedOrder) {
      const updatedOrder = updatedData.find(
        order => order.bnplNumber === selectedOrder.orderId
      );

      if (
        updatedOrder &&
        updatedOrder.status?.status !== selectedOrder.status
      ) {
        console.log(
          `Order ${selectedOrder.orderId} status changed from ${selectedOrder?.status} to ${updatedOrder?.status.status}`
        );

        if (
          selectedOrder.status === OrderStatus.CLIENT_VERIFICATION &&
          updatedOrder.status?.status !== OrderStatus.CLIENT_VERIFICATION
        ) {
          setShowCheckingClientModal(false);
          setShowConfirmCodeModal(true);
        }

        setSelectedOrder(prevOrder => ({
          ...(prevOrder ?? {}),
          status: updatedOrder?.status?.status,
          orderId: prevOrder?.orderId ?? 0
        }));
      }
    }
  };

  const handleItemClick = (item: {
    orderId: number;
    status: OrderStatus;
    time: string;
  }) => {
    // console.log('ITEM-STATUS:::');
    // console.log(item.status);
    // setOrderNumber(item.orderId);
    setSelectedOrder({
      orderId: item.orderId,
      status: item.status
    });
    switch (item.status) {
      case OrderStatus.SUCCESS:
        handleSuccessStatusClick(item.orderId);
        return;
      case OrderStatus.QR_CODE_GENERATION:
        handleQrCodeGenarationStatusClick();
        return;
      case OrderStatus.CLIENT_VERIFICATION:
        handleClientVerificationStatusClick();
        return;
      case OrderStatus.CODE_CHECKING:
        handleCodeCheckingStatusClick();
        return;
      case OrderStatus.REJECT:
        handleRejectStatusClick();
        return;
      case OrderStatus.RETURN:
        handleReturnStatusClick(item.orderId);
        return;
      default:
        return;
    }
  };

  const handleSuccessStatusClick = (orderNumber: number) => {
    getOrderDetailsAsync(orderNumber, navigate).then(res => {
      console.log('res?.data:::', res?.data);
      if (res?.data) {
        if (res?.data?.error_code === 404) {
          console.log('order is empty!!!');
          alert(res?.data?.message);
          return;
          //   useSignOut(navigateToAuth);
        }
        setOrder(mapResponse(res?.data?.data));
        setShowOrderDetailModal(true);
      }
    });
  };

  const handleReturnStatusClick = (orderNumber: number) => {
    getOrderDetailsAsync(orderNumber, navigate).then(res => {
      console.log('res?.data:::', res);
      if (res?.data) {
        if (res?.data?.error_code === 404) {
          console.log('order is empty!!!');
          setMessageFromResponse(res?.data?.message);
          setEmptyResponseModal(true);
          return;
          //   useSignOut(navigateToAuth);
        }
        setOrder(mapResponse(res?.data?.data));
        setShowReturnModal(true);
      }
    });
  };

  const handleCodeCheckingStatusClick = () => {
    setShowConfirmCodeModal(true);
  };

  const handleQrCodeGenarationStatusClick = () => {};

  const handleClientVerificationStatusClick = () => {
    setShowCheckingClientModal(true);
  };

  const handleRejectStatusClick = () => {
    setShowRejectModal(true);
  };

  return (
    <>
      <ConfirmCodeModal
        show={showConfirmCodeModal}
        onHide={() => setShowConfirmCodeModal(false)}
        onSuccessGenerateCode={v => {
          console.log('success');
          setCode(v);
          setShowGiveOrderModal(true);
          setShowConfirmCodeModal(false);
        }}
        onFailGenerateCode={() => {}}
        bnplId={selectedOrder && selectedOrder.orderId}
      />
      <GiveOrderModal
        show={showGiveOrderModal}
        code={code}
        onHide={() => {
          setCode('');
          setShowGiveOrderModal(false);
        }}
        onGiveOrderBtnClick={() => {}}
        dataFromConfirmModal={dataFromConfirmModal!}
        onWatchPhotoBtnClick={() => {
          setShowPhotoModal(true);
        }}
      />
      <ShowPhotoModal
        show={showPhotoModal}
        onHide={() => {
          setShowPhotoModal(false);
        }}
      />
      <CheckingClientModal
        time={'00:00'}
        show={showCheckingClientModal}
        onHide={() => setShowCheckingClientModal(false)}
      />
      <OrderDetailModal
        show={showOrderDetailModal}
        onHide={() => setShowOrderDetailModal(false)}
        order={order ?? defaultOrderDetail}
        onMakeReturn={() => {
          setShowOrderDetailModal(false);
          setShowMakeReturnModal(true);
        }}
      />
      <RejectModal
        show={showRejectModal}
        onHide={() => {
          setShowRejectModal(false);
        }}
      />
      <EmptyResponseModal
        show={showEmptyResponseModal}
        onHide={() => {
          setEmptyResponseModal(false);
        }}
        message={messageFromResponse}
      />
      <SuccessModal
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
        }}
      />
      <ReturnDetailModal
        show={showReturnModal}
        onHide={() => setShowReturnModal(false)}
        order={order ?? defaultOrderDetail}
      />
      <MakeReturnModal
        orderId={order?.orderNumber ?? ''}
        show={showMakeReturnModal}
        onHide={() => setShowMakeReturnModal(false)}
        onSuccessReturn={() => {
          setShowReturnModal(true);
          setShowMakeReturnModal(false);
        }}
        onFailReturn={() => {}}
      />
      {ordersList && ordersList.length === 0 ? (
        <h5 className="text-center text-danger">
          Нет транзакций за данный период
        </h5>
      ) : (
        <OrdersTable
          onItemClick={handleItemClick}
          onDataUpdate={handleDataUpdate}
        />
      )}
    </>
  );
};

export default OrdersList;
