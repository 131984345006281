import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import { Table } from 'react-bootstrap';
import { paymentsHistoryTableColumns } from './columns';
import { PaymentHistoryType } from './types';
import { useLoaderData } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortAsc,
  faSortDesc
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  onItemClick: (arg: number) => void;
};

export const PaymentsHistoryTable = ({ onItemClick }: Props) => {
  const { paymentsHistories } = useLoaderData() as {
    totalPages: number;
    paymentsHistories: PaymentHistoryType[];
  };
  const [sorting, setSorting] = useState([
    {
      id: 'operationDate',
      desc: false
    }
  ]);

  const { getFlatHeaders, getRowModel } = useReactTable({
    data: paymentsHistories,
    columns: paymentsHistoryTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableSorting: true,
    state: {
      sorting: sorting
    }
  });

  // console.log(paymentsHistories);

  return (
    <div className="scrollbar ms-n1 ps-1">
      <Table>
        <thead>
          <tr>
            {getFlatHeaders().map(header => {
              return (
                <th
                  key={header.id}
                  {...header.column.columnDef.meta?.headerProps}
                  className={classNames(
                    header.column.columnDef.meta?.headerProps?.className
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === 'asc'
                        ? 'Sort ascending'
                        : header.column.getNextSortingOrder() === 'desc'
                        ? 'Sort descending'
                        : 'Clear sort'
                      : undefined
                  }
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {header.column.getCanSort()
                    ? {
                        asc: (
                          <FontAwesomeIcon
                            className="mx-1 position-absolute fs-9"
                            icon={faSortDesc}
                          />
                        ),
                        desc: (
                          <FontAwesomeIcon
                            className="mx-1 position-absolute fs-9"
                            icon={faSortAsc}
                          />
                        )
                      }[header.column.getIsSorted() as string] ?? (
                        <FontAwesomeIcon
                          className="mx-1 position-absolute fs-9"
                          icon={faSort}
                        />
                      )
                    : null}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {getRowModel().rows.map(row => (
            <tr
              key={row.id}
              className="pe-auto"
              onClick={() => onItemClick(Number(row.original.orderNumber))}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
