import { Navbar } from 'react-bootstrap';
import { NavItems, NavbarBrand } from './components';

const Header = () => (
  <Navbar className="navbar-top fixed-top" expand variant="">
    <div className="navbar-collapse justify-content-between">
      <NavbarBrand />

      <NavItems />
    </div>
  </Navbar>
);

export default Header;
