import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { transactionsTableColumns } from './columns';
import classNames from 'classnames';
import { useSignOut } from 'hooks/useSignOut';
import useNavigateUser from 'hooks/useNavigateUser';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortAsc,
  faSortDesc
} from '@fortawesome/free-solid-svg-icons';
import { TransactionsListResponseType } from 'modules/transactions/types';
import useTransactionsSearchStore from 'modules/transactions/transactions-search-filter/store/store';
import { useLoaderData } from 'react-router-dom';

export const TransactionsTable = () => {
  const { transactions } = useLoaderData() as TransactionsListResponseType;
  const setFilteredData = useTransactionsSearchStore.use.setFilteredData();
  const [sorting, setSorting] = useState([
    {
      id: 'createdAt',
      desc: false
    }
  ]);

  const navigateToAuth = useNavigateUser('/auth/sign-in');
  const searchTerm = useTransactionsSearchStore.getState().search;

  const filteredData = useMemo(() => {
    if (!searchTerm) return transactions;
    const filteredRes = transactions.filter(transaction =>
      transaction.operationId.toString().includes(searchTerm)
    );
    setFilteredData(filteredRes);
    return filteredRes;
  }, [searchTerm, transactions]);

  const { getFlatHeaders, getRowModel } = useReactTable({
    data: filteredData,
    columns: transactionsTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableSorting: true,
    state: {
      sorting: sorting
    }
  });

  return (
    <div className="scrollbar ms-n1 ps-1">
      <Table>
        <thead>
          <tr>
            {getFlatHeaders().map(header => {
              return (
                <th
                  key={header.id}
                  {...header.column.columnDef.meta?.headerProps}
                  className={classNames(
                    header.column.columnDef.meta?.headerProps?.className
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                  title={
                    header.column.getCanSort()
                      ? header.column.getNextSortingOrder() === 'asc'
                        ? 'Sort ascending'
                        : header.column.getNextSortingOrder() === 'desc'
                        ? 'Sort descending'
                        : 'Clear sort'
                      : undefined
                  }
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {header.column.getCanSort()
                    ? {
                        asc: (
                          <FontAwesomeIcon
                            className="mx-1 position-absolute fs-9"
                            icon={faSortDesc}
                          />
                        ),
                        desc: (
                          <FontAwesomeIcon
                            className="mx-1 position-absolute fs-9"
                            icon={faSortAsc}
                          />
                        )
                      }[header.column.getIsSorted() as string] ?? (
                        <FontAwesomeIcon
                          className="mx-1 position-absolute fs-9"
                          icon={faSort}
                        />
                      )
                    : null}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {getRowModel().rows.map(row => (
            <tr
              key={row.id}
              onClick={() => {
                if (!row) {
                  console.log('sign out from transactions-table');
                  useSignOut(navigateToAuth);
                }
                console.log('ROW');
                // console.log('row oriinal:', row.original);
                // onItemClick({
                //   orderNumber: row.original.orderNumber,
                //   status: row.original.status.status,
                //   time:
                //     transactions?.find(
                //       i => i.orderNumber === row.original.orderNumber
                //     )?.status.time ?? '00:00'
                // });
              }}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
