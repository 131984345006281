import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Offcanvas, Row, Col } from 'react-bootstrap';
import { separateThousands } from 'helpers';
import ActivityTimeline from 'components/timelines/ActivityTimeline';
// import ActivityTimeline from 'components/timelines/ActivityTimeline';
// import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type PaymentDetail = {
  borrower: {
    fullName: string;
    phoneNumber: string;
  };
  trusteeList: {
    fullName: string;
    phoneNumber: string;
  }[];
  paymentHistory: {
    status: number;
    paymentDate: string;
    paymentSum: number;
  }[];
  paspayOrderNumber: number;
  orderNumber: number;
  operationDate: string;
  totalSum: number;
  period: string;
  monthPaymentSum: number;
  paidPeriods: string;
};

type Props = {
  show: boolean;
  onHide: () => void;
  onCloseBtnClick: () => void;
  detail: PaymentDetail | null;
};

export const PaymentDetailsDrawer = ({
  show,
  onCloseBtnClick,
  onHide,
  detail
}: Props) => {
  console.log('detail::', detail);

  return (
    <Offcanvas
      className="settings-panel border-0"
      show={show}
      onHide={onHide}
      placement="end"
    >
      <Offcanvas.Header className="align-items-start border-bottom border-translucent flex-column">
        <div className="pt-1 w-100 d-flex justify-content-between align-items-start">
          <button className="btn p-1 fw-bolder" onClick={onCloseBtnClick}>
            <FontAwesomeIcon icon={faTimes} className="fs-8" />
          </button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-card scrollbar">
        {detail && (
          <>
            <div className="border-bottom border-translucent">
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary p-2">Заемщик</p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis">
                    {detail?.borrower?.fullName}
                  </h5>
                  <p className="text-body-tertiary">
                    {detail?.borrower?.phoneNumber}
                  </p>
                </Col>
              </Row>
              {detail.trusteeList.map((item, index) => (
                <Row className="gy-5" key={index}>
                  <Col xs={12} md={6}>
                    <p className="text-body-tertiary p-2 ">
                      Доверительное лицо {index + 1}
                    </p>
                  </Col>
                  <Col xs={12} md={6}>
                    <h5 className="text-body-emphasis">{item.fullName}</h5>
                    <p className="text-body-tertiary">{item.phoneNumber}</p>
                  </Col>
                </Row>
              ))}
            </div>
            <div className="mt-4 border-bottom border-translucent ">
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary px-2">№ заказа Paspay</p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis mt-1">
                    {detail.paspayOrderNumber}
                  </h5>
                </Col>
              </Row>
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary px-2">№ заказа oma.kz</p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis mt-1">
                    {detail.orderNumber}
                  </h5>
                </Col>
              </Row>
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary px-2">Дата операции</p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis mt-1">
                    {detail.operationDate}
                  </h5>
                </Col>
              </Row>
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary px-2">Общая сумма </p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis mt-1">
                    {separateThousands(detail.totalSum)} ₸
                  </h5>
                </Col>
              </Row>
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary px-2">Период </p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis mt-1">{detail.period}</h5>
                </Col>
              </Row>
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary px-2">
                    Сумма оплаты в месяц
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis mt-1">
                    {detail.monthPaymentSum} ₸
                  </h5>
                </Col>
              </Row>
              <Row className="gy-5">
                <Col xs={12} md={6}>
                  <p className="text-body-tertiary px-2">Оплаченные периоды</p>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-body-emphasis mt-1">
                    {detail.paidPeriods} / {detail.period} мес
                  </h5>
                </Col>
              </Row>
            </div>
            <div>
              <h5 className="text-body-emphasis" style={{ marginTop: '-10px' }}>
                Хронология платежей
              </h5>
              <ActivityTimeline
                data={detail.paymentHistory.sort((a, b) => {
                  //@ts-expect-error str
                  return new Date(a.paymentDate) - new Date(b.paymentDate);
                })}
              />
            </div>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
