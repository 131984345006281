import { redirectDocument } from 'react-router-dom';

export const authLoader = () => {
  const pathname = window.location.pathname;

  if (pathname === '/auth' || pathname === '/auth/') {
    return redirectDocument('/auth/sign-in');
  }

  return null;
};
