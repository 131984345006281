import axios from 'axios';
import { SecureLocalStorageHelper } from 'local-storage';

const api = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Methods': ['GET', 'POST'],
    'Accept-Language': ['en-us', 'ru-Ru']
  }
});

api.interceptors.request.use(config => {
  // console.log('request intercept');
  const CLIENT_ID = 'stp_paspay_web';
  const CLIENT_SECRET = 'XfvfFp7FDT';
  const LOGIN_URL = 'https://auth.paspay.kz/oauth/token';

  const isLoginRequest = config.url === LOGIN_URL;
  // console.log('isLoginReguest:::', isLoginRequest);

  if (isLoginRequest) {
    config.headers.Authorization =
      'Basic ' + window.btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers.Authorization =
      'Bearer ' + SecureLocalStorageHelper.getBearerToken();
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

export default api;
