import { AxiosResponse } from 'axios';
import { api } from 'config/axios';

type RequestParams = {
  orderNumber: string;
  sum: number;
  bin: number;
};

type Response = {
  amount: number;
  orderNumber: string;
  bnplUrl: string;
  qrBase64: string;
  errorCode?: number;
  bnplId: number;
};

export const getInstallmentQrCodeAsync = async ({
  orderNumber,
  sum,
  bin
}: RequestParams) => {
  const URL = 'https://guard.paspay.kz/bnpl/generate-qr';

  try {
    const res: AxiosResponse<Response> = await api.post(URL, {
      order_number: orderNumber,
      amount: sum,
      bin
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
