// import ThemeToggler from 'components/common/ThemeToggler';
import { SignInForm } from 'modules/authorization';

const SignInPage = () => (
  <>
    {/* <ThemeToggler /> */}
    <SignInForm />
  </>
);

export default SignInPage;
