import { createSelectors } from 'config/zustand';
import { create } from 'zustand';

type State = {
  date: {
    startDate: string | null;
    endDate: string | null;
  };
  statusParams: string[];
};

const initialState: State = {
  date: {
    startDate: null,
    endDate: null
  },
  statusParams: []
};

type Action = {
  setDate: (date: State['date']) => void;
  setStatusParams: (params: State['statusParams']) => void;
  reset: () => void;
};

const useGlobalStoreBase = create<State & Action>(set => ({
  date: initialState['date'],
  statusParams: initialState['statusParams'],
  setStatusParams: params => set(() => ({ statusParams: params })),
  setDate: date => set(() => ({ date: date })),
  reset: () => {
    set(initialState);
  }
}));

const useGlobalStore = createSelectors(useGlobalStoreBase);

export default useGlobalStore;
