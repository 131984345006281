import { SearchParamsKey } from 'enums';
import { useGlobalStore } from 'global-store';
import { getLastPathName, getQueryConcatenator } from 'helpers';
import {
  useOrdersFilterStore,
  useOrdersPaginationStore,
  useOrdersSearchStore
} from 'modules/orders';
import {
  usePaymentsHistoryPaginationStore,
  usePaymentsHistorySearchStore
} from 'modules/payments-history';
import { Nav } from 'react-bootstrap';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import './style.scss';

interface PageTitlesType {
  orders: string;
  'event-log': string;
  'payment-history': string;
}

const pageTitle: PageTitlesType = {
  orders: 'Заказы',
  'event-log': 'Журнал событий',
  'payment-history': 'История выплат'
};

const InstallmentPage = () => {
  const { pathname } = useLocation();
  const lastPathName = getLastPathName(pathname);

  const date = useGlobalStore.use.date();

  const ordersStatus = useOrdersFilterStore.use.status();
  const ordersPageIndex = useOrdersPaginationStore.use.pageIndex();
  const ordersSearch = useOrdersSearchStore.use.search();

  const paymentsHistoryPageIndex =
    usePaymentsHistoryPaginationStore.use.pageIndex();
  const paymentsSearch = usePaymentsHistorySearchStore.use.search();

  const getOrdersQueryParams = () => {
    const concat = getQueryConcatenator();
    if (ordersPageIndex !== 0) {
      concat.set(SearchParamsKey.PageIndex, ordersPageIndex.toString());
    }
    if (date.startDate !== null && date.endDate !== null) {
      concat
        .set(SearchParamsKey.StartDate, date.startDate)
        .set(SearchParamsKey.EndDate, date.endDate);
    }
    if (ordersStatus.length > 0) {
      concat.setArray(SearchParamsKey.PaymentStatus, ordersStatus);
    }
    if (ordersSearch.length > 0) {
      concat.set(SearchParamsKey.Search, ordersSearch);
    }

    return concat.toString();
  };

  // const getEventsLogQueryParams = () => {
  //   const concat = getQueryConcatenator();
  //   if (eventsLogPageIndex !== 0) {
  //     concat.set(SearchParamsKey.PageIndex, eventsLogPageIndex.toString());
  //   }
  //   if (date.startDate !== null && date.endDate !== null) {
  //     concat
  //       .set(SearchParamsKey.StartDate, date.startDate)
  //       .set(SearchParamsKey.EndDate, date.endDate);
  //   }
  //   if (eventsLogSearch.length > 0) {
  //     concat.set(SearchParamsKey.Search, eventsLogSearch);
  //   }

  //   return concat.toString();
  // };

  const getPaymentsHistoryQueryParams = () => {
    const concat = getQueryConcatenator();
    if (paymentsHistoryPageIndex !== 0) {
      concat.set(
        SearchParamsKey.PageIndex,
        paymentsHistoryPageIndex.toString()
      );
    }
    if (date.startDate !== null && date.endDate !== null) {
      concat
        .set(SearchParamsKey.StartDate, date.startDate)
        .set(SearchParamsKey.EndDate, date.endDate);
    }
    if (paymentsSearch.length > 0) {
      concat.set(SearchParamsKey.Search, paymentsSearch);
    }

    return concat.toString();
  };

  //@ts-expect-error str
  const title = pageTitle[lastPathName];
  return (
    <>
      <h2 className="text-body-emphasis" style={{ marginBottom: '62px' }}>
        {title}
      </h2>

      <Nav className="nav-underline mb-5">
        <Nav.Item>
          <NavLink
            className={'nav-link'}
            to={{
              pathname: '/installment/orders',
              search: getOrdersQueryParams()
            }}
          >
            Заказы
          </NavLink>
        </Nav.Item>
        {/* <Nav.Item>
          <NavLink
            className={'nav-link'}
            to={{
              pathname: '/installment/event-log',
              search: getEventsLogQueryParams()
            }}
          >
            Журнал событий
          </NavLink>
        </Nav.Item> */}
        <Nav.Item>
          <NavLink
            className={'nav-link'}
            to={{
              pathname: '/installment/payment-history',
              search: getPaymentsHistoryQueryParams()
            }}
          >
            История выплат
          </NavLink>
        </Nav.Item>
      </Nav>

      <Outlet />
    </>
  );
};

export default InstallmentPage;
