import { queryClient } from 'index';
import { SecureLocalStorageHelper } from 'local-storage';

type NavigateFnType = () => void;

export const useSignOut = (navigateFn: NavigateFnType) => {
  queryClient.removeQueries({ queryKey: ['aboutMeData'], exact: true });
  queryClient.clear();
  SecureLocalStorageHelper.clearAll();
  localStorage.clear();
  navigateFn();
};
