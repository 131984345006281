import { useDebouncedCallback } from 'use-debounce';
import SearchBox from 'components/common/SearchBox';
import useTransactionsSearchStore from './store/store';
// import { TransactionsListResponseType } from '../types';
import { SearchParamsKey } from 'enums';
import { useSearchParams } from 'react-router-dom';

const TransactionsSearchFilter = () => {
  // const { transactions } = useLoaderData() as TransactionsListResponseType;
  const [searchParams, setSearchParams] = useSearchParams({
    [SearchParamsKey.Search]: ''
  });
  const searchValue = useTransactionsSearchStore.getState().search;
  const setSearchValue = useTransactionsSearchStore.use.setSearch();

  const filteredData = useTransactionsSearchStore.getState().filteredData;

  const debounced = useDebouncedCallback(value => {
    setSearchValue(value);
    if (value === '') {
      setSearchValue('');
      searchParams.delete(SearchParamsKey.Search);
      console.log('clear search params');
    } else {
      setSearchValue(value);
      searchParams.set(SearchParamsKey.Search, value);
      console.log('set search params');
    }
    setSearchParams(searchParams);
  }, 1000);

  const isNotFound =
    !filteredData.length && searchValue !== '' && searchValue !== null;

  return (
    <div>
      <SearchBox
        className="my-auto"
        defaultValue={searchValue ?? ''}
        placeholder="Поиск по номеру транзакции"
        onChange={e => debounced(e.target.value)}
      />
      {isNotFound && <div style={{ color: 'red' }}>Ничего не найдено</div>}
    </div>
  );
};

export default TransactionsSearchFilter;
