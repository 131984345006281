import { api } from 'config/axios';
// import useNavigateUser from 'hooks/useNavigateUser';
// import { useSignOut } from 'hooks/useSignOut';

export const getOrderPhotoAsync = async (id: number) => {
  const URL = 'https://guard.paspay.kz/bnpl/show-photo';
  // const navigateToAuth = useNavigateUser('/auth/sign-in');

  try {
    const res = await api.post(URL, { bnpl_id: id });
    return res;
  } catch (err) {
    console.log(err);
    // useSignOut(navigateToAuth);
  }
};
