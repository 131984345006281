import { api } from 'config/axios';
import {
  LoginRequestParams,
  LoginResponseType,
  MerchantInfoResponseType
} from './types';
import axios, { AxiosResponse } from 'axios';

export const loginByBINorIIN = async ({
  bin,
  password
}: LoginRequestParams) => {
  const LOGIN_URL = 'https://auth.paspay.kz/oauth/token';
  try {
    const formData = new FormData();
    formData.append('username', bin);
    formData.append('password', password);
    formData.append('grant_type', 'password');

    const res: AxiosResponse<LoginResponseType> = await api.post(
      LOGIN_URL,
      formData
    );
    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAboutMeInfo = async () => {
  try {
    const GET_INFO_URL = 'https://guard.paspay.kz/cabinet/get-info';
    const response =
      await api.get<AxiosResponse<MerchantInfoResponseType>>(GET_INFO_URL);
    if (!response.data) {
      throw new Error('No data received');
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw new Error('Unauthorized');
    }
    throw error;
  }
};
