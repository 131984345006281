import { AxiosResponse } from 'axios';
import { api } from 'config/axios';

export type CheckClientPhotoRequestParams = {
  bnpl_id?: number;
};

export type CheckClientPhotoResponseType = {
  message: string;
  error_code: number;
  data: string;
};

export const checkClientPhotoAsync = async ({
  bnpl_id
}: CheckClientPhotoRequestParams) => {
  const URL = 'https://gateway.paspay.kz/bnpl/show-photo';

  try {
    const res: AxiosResponse<CheckClientPhotoResponseType> = await api.post(
      URL,
      {
        bnpl_id
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};
