import { AxiosResponse } from 'axios';
import { api } from 'config/axios';

export type CheckOrderCodeRequestParams = {
  bnpl_id?: number;
  otp: string;
};

export type CheckOrderCodeResponseType = {
  errorCode: number;
  message: string;
  // success: boolean;
  data: {
    bnplId: number;
    orderNumber: string;
    date: string;
    amount: number;
    paymentCount: number;
    manager: string;
    buyer: string;
  };
};

export const sendCheckOrderCodeAsync = async ({
  bnpl_id,
  otp
}: CheckOrderCodeRequestParams) => {
  const URL = 'https://gateway.paspay.kz/bnpl/check-order-code';

  try {
    const res: AxiosResponse<CheckOrderCodeResponseType> = await api.post(URL, {
      bnpl_id,
      otp
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
