import { SecureLocalStorageHelper } from 'local-storage';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import {
  PaymentsHistoryResponseType,
  getPaymentsHistoryAsync
} from 'modules/payments-history';
import { SearchParamsKey } from 'enums';
import { PaymentHistoryType } from 'modules/payments-history/payments-history-list/components/table';
import { useSignOut } from 'hooks/useSignOut';
import useNavigateUser from 'hooks/useNavigateUser';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const isAuth = SecureLocalStorageHelper.hasBearerToken();
  if (!isAuth) {
    return redirect('/auth/sign-in');
  }

  const pageIndex =
    new URL(request.url).searchParams.get(SearchParamsKey.PageIndex) ??
    undefined;
  const startDate =
    new URL(request.url).searchParams.get(SearchParamsKey.StartDate) ??
    undefined;
  const endDate =
    new URL(request.url).searchParams.get(SearchParamsKey.EndDate) ?? undefined;
  const orderNumber =
    new URL(request.url).searchParams.get(SearchParamsKey.Search) ?? undefined;

  const res = await getPaymentsHistoryAsync(Number(pageIndex ?? 0), {
    startDate,
    endDate,
    orderNumber
  }).catch(error => {
    const navigateToAuth = useNavigateUser('/auth/sign-in');
    if (error.response.status === 401) {
      console.log('unauthorized...');
      useSignOut(navigateToAuth);
      console.log(res);
    }
  });

  if (!res) {
    console.log('data is undefined');
    return {
      totalPages: 0,
      paymentsHistories: []
    };
  }

  // if (res?.data.response_list.length) {
  //   console.log(true);
  // }

  // if (!res) {
  //   console.log('error');
  // }

  return {
    totalPages: res?.data.total_pages,
    paymentsHistories: mapResponse(res.data.response_list)
  };
};

const mapResponse = (
  res: PaymentsHistoryResponseType['response_list']
): PaymentHistoryType[] =>
  res.map(item => ({
    orderNumber: item.bnpl_id?.toString() ?? '',
    merchantOrderNumber: item.order_id?.toString() ?? '',
    operationDate: item.created_at ?? '',
    totalSum: item.amount ?? 0,
    period: item.payment_count?.toString() ?? '',
    paymentDate: item.payment_date ?? '',
    paymentSum: {
      sum: item.payment_sum ?? 0,
      type: 'success'
    },
    contacts: {
      fullName: item.manager ?? '',
      phoneNumber: ''
    },
    paidPeriods: item.success_payment_number?.toString() ?? ''
  }));
