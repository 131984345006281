import { AxiosError, HttpStatusCode } from 'axios';
import { SecureLocalStorageHelper } from 'local-storage';
import { loginByBINorIIN } from 'modules/authorization';
import { ActionFunctionArgs, json, redirect } from 'react-router-dom';

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const bin = formData.get('bin') as string;
  const password = formData.get('password') as string;

  try {
    const res = await loginByBINorIIN({
      bin,
      password
    });

    if (res.status === HttpStatusCode.Ok) {
      SecureLocalStorageHelper.setBearerToken(res.data.access_token);
      return redirect('/');
    }
  } catch (err) {
    const error = err as AxiosError;
    console.log(err);

    console.log('MY ERROR:::', err);
    if (
      error.response?.status === HttpStatusCode.BadRequest ||
      error.response?.status === HttpStatusCode.Unauthorized
    ) {
      return json({
        message: 'invalid_bin_or_password',
        status: error.response.status
      });
    }
  }

  return null;
};
