import { create } from 'zustand';
import { UserType } from './types';
import { createSelectors } from 'config/zustand';

type State = {
  user: UserType | null;
};

const initialState: State = {
  user: null
};

type Action = {
  setUser: (user: State['user']) => void;
  reset: () => void;
};

const useUserStoreBase = create<State & Action>(set => ({
  user: null,
  setUser: user => set(() => ({ user: user })),
  reset: () => {
    set(initialState);
  }
}));

const useUserStore = createSelectors(useUserStoreBase);

export default useUserStore;
