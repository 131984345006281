import { AxiosResponse } from 'axios';
import { api } from 'config/axios';

export type PaymentDetailsResponseType = {
  error_code: number;
  message: string;
  data: {
    borrower: string;
    borrower_phone: string;
    trustees: {
      nameSurname: string;
      phoneNumber: string;
    }[];
    bnplId: number;
    orderId: string;
    createdAt: string;
    amount: number;
    paymentCount: string | null;
    singlePaymentAmount: string | null;
    successPaymentNumber: number;
    paymentHistory: {
      status: number;
      payment_date: string;
      payment_sum: number;
    }[];
  };
};

export const getPaymentsDetails = async (paymentId: number) => {
  const URL = `https://guard.paspay.kz/bnpl/${paymentId}`;

  try {
    const res: AxiosResponse<PaymentDetailsResponseType> = await api.post(URL);
    return res;
  } catch (err) {
    console.log(err);
  }
};
