import { api } from 'config/axios';
import dayjs from 'dayjs';
import { TransactionsListRequestParams } from '../types';

const apiUrl = 'https://guard.paspay.kz';

export const getTransactionsListAsync = async (
  pageIndex: number,
  { fromDate, tillDate }: Partial<TransactionsListRequestParams>
) => {
  const res = await api.post(
    `${apiUrl}/cabinet/get-transactions?page=${pageIndex}&fromDate=${dayjs(
      fromDate
    ).format('YYYY-MM-DD')}&tillDate=${dayjs(tillDate).format('YYYY-MM-DD')}`,
    {
      fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
      tillDate: dayjs(tillDate).format('YYYY-MM-DD')
    }
  );

  return res;
};
