import {
  faEye,
  faEyeSlash,
  faKey,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Link,
  useActionData,
  useNavigation,
  useSubmit
} from 'react-router-dom';
import { Form as RouterForm } from 'react-router-dom';
import LeadDetailsNavbar from './components/LeadDetailsNavbar';

const SignInForm = () => {
  const navigation = useNavigation();
  const submit = useSubmit();
  const actionData = useActionData() as {
    message: string;
    status: number;
  } | null;
  const [signInVariant, setSignInVariant] = useState<string>('iin');

  const [bin, setBin] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const submitForm = () => {
    submit(
      {
        bin,
        password
      },
      { method: 'post' }
    );
    localStorage.setItem('bin', bin);
  };

  const areEmptyFields = bin.length === 0 || password.length === 0;
  const isSubmitting = navigation.state === 'submitting';

  const input = document.getElementById('password');
  const isInvalidAuthData = actionData?.message === 'invalid_bin_or_password';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key !== 'Enter') {
      return;
    }
    if (areEmptyFields) {
      return;
    }
    if (isSubmitting) {
      return;
    }
    submitForm();
  };

  const handlePasswordToggle = () => {
    if (showPassword) {
      setShowPassword(false);
      input?.setAttribute('type', 'password');
    } else {
      setShowPassword(true);
      input?.setAttribute('type', 'text');
    }
  };

  return (
    <RouterForm method="post" onKeyDown={handleKeyDown}>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Войти в кабинет</h3>
        <p className="text-body-tertiary">
          Получите доступ к своей учётной записи
        </p>
      </div>
      <LeadDetailsNavbar value={signInVariant} setValue={setSignInVariant} />
      {signInVariant == 'iin' ? (
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">БИН/ИИН</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="bin"
              name="bin"
              value={bin}
              onChange={e => setBin(e.target.value)}
              className={`form-icon-input ${
                isInvalidAuthData ? 'border-danger' : ''
              }`}
              placeholder="БИН/ИИН"
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
      ) : (
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              // className="form-icon-input"
              className={`focus-ring focus-ring-light form-icon-input ${
                isInvalidAuthData ? 'border-danger' : ''
              }`}
              placeholder="email"
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-body fs-9 form-icon"
            />
          </div>
        </Form.Group>
      )}
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Пароль</Form.Label>
        <div className="d-flex justify-content-end form-icon-container">
          <Form.Control
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={`z-0 border-right-0 focus-ring focus-ring-light form-icon-input ${
              isInvalidAuthData ? 'border-danger' : ''
            }`}
            placeholder="Пароль"
          />
          <FontAwesomeIcon icon={faKey} className="fs-9 form-icon" />
          <Button className="position-absolute" onClick={handlePasswordToggle}>
            <FontAwesomeIcon
              className="position-absolute"
              icon={showPassword ? faEyeSlash : faEye}
            />
          </Button>
        </div>
      </Form.Group>
      {isInvalidAuthData && (
        <p className="text-danger">Неверный БИН/ИИН или пароль</p>
      )}
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Запомнить меня
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link to={`/reset/forgot-password`} className="fs-9 fw-semibold">
            Забыли пароль ?
          </Link>
        </Col>
      </Row>
      <Button
        variant="secondary"
        className="w-100 mb-3"
        onClick={submitForm}
        disabled={areEmptyFields || isSubmitting}
      >
        {isSubmitting ? 'Загрузка...' : 'Войти'}
      </Button>
    </RouterForm>
  );
};

export default SignInForm;
