import { AxiosResponse } from 'axios';
import { api } from 'config/axios';

export type IssueAnOrderRequestParams = {
  bnpl_id?: number;
};

export type IssueAnOrderResponseType = {
  errorCode: number;
  message: string;
  data: null;
};

export const sendIssueAnOrderCodeAsync = async ({
  bnpl_id
}: IssueAnOrderRequestParams) => {
  const URL = 'https://gateway.paspay.kz/bnpl/issue-bnpl';

  try {
    const res: AxiosResponse<IssueAnOrderResponseType> = await api.post(URL, {
      bnpl_id
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
