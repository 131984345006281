import { redirectDocument } from 'react-router-dom';

export const loader = () => {
  const pathName = window.location.pathname;

  if (pathName === '/installment' || pathName === '/installment/') {
    return redirectDocument('/installment/orders');
  }

  return null;
};
