import { api } from 'config/axios';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { SearchParamsKey } from 'enums';

export type OrdersListRequestParams = {
  startDate: string;
  endDate: string;
  orderNumber: string;
  paymentStatus: string[];
};

export type OrdersListResponseType = {
  totalPages: number;
  totalElements: number;
  responseList: {
    bnplId: number;
    orderId: number;
    createdAt: string;
    amount: number;
    paymentCount: number;
    buyer: string;
    manager: string;
    status: string | null;
  }[];
};

const DATE_FORMAT = 'YYYY-MM-DD';

export const getOrdersListAsync = async (
  pageIndex: number,
  {
    startDate = dayjs(new Date()).format(DATE_FORMAT),
    endDate = dayjs(new Date()).format(DATE_FORMAT),
    orderNumber,
    paymentStatus = []
  }: Partial<OrdersListRequestParams>
) => {
  const statuses = paymentStatus?.map(i => {
    return `${SearchParamsKey.PaymentStatus}=${i}`;
  });
  console.log('statuses: ', statuses);

  const str = `${statuses.join('&')}`;
  console.log('str: ', str);
  // console.log(str);
  const URL = `https://guard.paspay.kz/bnpl/orders?page=${pageIndex}&startDate=${startDate}&endDate=${endDate}${
    paymentStatus.length > 0 ? '&' + str : ''
  }${orderNumber ? '&orderNumber=' + orderNumber : ''}`;

  try {
    const res: AxiosResponse<OrdersListResponseType> = await api.post(URL, {
      startDate,
      endDate,
      orderNumber,
      paymentStatus
    });

    return res;
  } catch (err) {
    console.log(err);
  }
};
