import 'dayjs/locale/ru';
import { TransactionsTable } from 'modules/transactions/transactions-list/components/table/transactions-table';
import TransactionsFooter from '../transactions-footer/transactions-footer';
import { useLoaderData } from 'react-router-dom';

export default function TransactionsList() {
  const { transactions } = useLoaderData();

  // const [fromDate, setFromDate] = useState(
  //   searchParams.get(SearchParamsKey.StartDate)
  // );
  // const [tillDate, setTillDate] = useState(
  //   searchParams.get(SearchParamsKey.EndDate)
  // );
  // const [transactions, setTransactions] = useState([]);
  // const [totalPages, setTotalPages] = useState(0);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);

  // const currentPage = useTransactionsPaginationStore.getState().pageIndex;
  // const setCurrentPage = useTransactionsPaginationStore.use.setPageIndex();
  // const navigateToAuth = useNavigateUser('/auth/sign-in');

  // const setSearchTransactionsValue = useTransactionsSearchStore.use.setSearch();

  // useEffect(() => {
  //   fetchTransactions(1);
  // }, []);

  // async function fetchTransactions(currentPage) {
  //   try {
  //     setLoading(true);
  //     setError(false);
  //     const { data } = await api.post(
  //       `${apiUrl}/cabinet/get-transactions?page=${currentPage - 1}`,
  //       {
  //         fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
  //         tillDate: dayjs(tillDate).format('YYYY-MM-DD')
  //       }
  //     );
  //     searchParams.delete(SearchParamsKey.Search);
  //     searchParams.set(
  //       SearchParamsKey.StartDate,
  //       dayjs(fromDate).format('YYYY-MM-DD')
  //     );
  //     searchParams.set(
  //       SearchParamsKey.EndDate,
  //       dayjs(tillDate).format('YYYY-MM-DD')
  //     );
  //     setSearchTransactionsValue('');
  //     setSearchParams(searchParams);
  //     setTransactions(data?.transactions);
  //     setTotalPages(data?.total_pages);
  //     setCurrentPage(currentPage);
  //     console.log(
  //       'transactions: ',
  //       transactions,
  //       'current page: ',
  //       currentPage,
  //       'total pages: ',
  //       totalPages
  //     );
  //   } catch (err) {
  //     console.log('err: ', err);
  //     if (
  //       err.response &&
  //       err.response.status === 401 &&
  //       err.response.data.message
  //     ) {
  //       localStorage.removeItem('accessToken');
  //       localStorage.removeItem('isUserValid');
  //       console.log('Unauthorized...');
  //       setError('unauthorized', true);
  //     } else {
  //       setError(true);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  console.log('render from transaction-list...');

  // if (loading) {
  //   return <h3>Идет загрузка...</h3>;
  // }

  // if (error === 'unauthorized') {
  //   useSignOut(navigateToAuth);
  // }

  // if (error) {
  //   return (
  //     <div className="text-center">
  //       Ошибка при получении данных. Попробуйте обновить страницу.
  //     </div>
  //   );
  // }

  return (
    <>
      {transactions && transactions.length === 0 ? (
        <h5 className="text-center text-danger">
          Нет транзакций за данный период
        </h5>
      ) : (
        <>
          <TransactionsTable />
          <TransactionsFooter />
        </>
      )}
    </>
  );
}
