import { useNavigate } from 'react-router-dom';

const useNavigateUser = (path: string) => {
  const navigate = useNavigate();

  return () => {
    navigate(path);
  };
};

export default useNavigateUser;
