import { useState } from 'react';
import Button from 'components/base/Button';
import { ModalBase } from 'new-components';
import { Col, Form, Row } from 'react-bootstrap';
import { DateFormatter, separateThousands } from 'helpers';
import { sendIssueAnOrderCodeAsync } from 'modules/installment/api/issue-an-order';
import useCreateBillStore from '../store/store';

interface DataFromConfirmModal {
  bnplId: number;
  orderNumber: string;
  date: string;
  amount: number;
  paymentCount: number;
  manager: string;
  buyer: string;
}

interface Props {
  show: boolean;
  code: string;
  onHide: () => void;
  onWatchPhotoBtnClick: () => void;
  onGiveOrderBtnClick: () => void;
  closeWSConnection: () => void;
  dataFromConfirmModal: DataFromConfirmModal;
}

export const GiveOrderModal = ({
  show,
  code,
  onHide,
  onWatchPhotoBtnClick,
  closeWSConnection,
  dataFromConfirmModal
}: Props) => {
  const [loading, setLoading] = useState(false);
  const tempBnplIdFromStore = useCreateBillStore(state => state.tempBnplId);

  const submitData = async () => {
    try {
      const res = await sendIssueAnOrderCodeAsync({
        bnpl_id: tempBnplIdFromStore
      });
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClick = () => {
    setLoading(true);
    submitData();
    onHide();
  };

  return (
    <ModalBase
      show={show}
      onHide={() => {
        onHide();
        closeWSConnection();
      }}
      title="Проверочный код"
      titleCentered
    >
      <Form.Group className="text-center mb-2">
        <label
          style={{
            color: '#222834',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '18px',
            marginBottom: '12px',
            padding: 0
          }}
          className="modal-label"
        >
          Ввести проверочный код
        </label>
        <Form.Control
          className="text-center"
          style={{
            maxWidth: '210px',
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 700
          }}
          value={code}
          readOnly
        />
      </Form.Group>

      <div className="w-100">
        <div style={{ marginBottom: '12px' }}>
          <Row className="" style={{ marginBottom: '12px' }}>
            <Col>
              <div
                style={{
                  color: '#6E7891',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 600
                }}
              >
                Номер заказа
              </div>
            </Col>
            <Col>
              <div
                className="text-end"
                style={{
                  color: '#31374A',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 700
                }}
              >
                {separateThousands(Number(dataFromConfirmModal?.amount))}
              </div>
            </Col>
          </Row>

          <Row className="" style={{ marginBottom: '12px' }}>
            <Col>
              <div
                style={{
                  color: '#6E7891',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 600
                }}
              >
                Дата операции
              </div>
            </Col>
            <Col>
              <div
                className="text-end"
                style={{
                  color: '#31374A',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 700
                }}
              >
                {DateFormatter(dataFromConfirmModal?.date)}
              </div>
            </Col>
          </Row>

          <Row className="" style={{ marginBottom: '12px' }}>
            <Col>
              <div
                style={{
                  color: '#6E7891',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 600
                }}
              >
                Сумма
              </div>
            </Col>
            <Col>
              <div
                className="text-end"
                style={{
                  color: '#31374A',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 700
                }}
              >
                {separateThousands(Number(dataFromConfirmModal?.amount))} ₸
              </div>
            </Col>
          </Row>

          <Row className="" style={{ marginBottom: '12px' }}>
            <Col>
              <div
                style={{
                  color: '#6E7891',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 600
                }}
              >
                Период
              </div>
            </Col>
            <Col>
              <div
                className="text-end"
                style={{
                  color: '#31374A',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 700
                }}
              >
                {dataFromConfirmModal?.paymentCount} месяца
              </div>
            </Col>
          </Row>

          <Row className="" style={{ marginBottom: '12px' }}>
            <Col>
              <div
                style={{
                  color: '#6E7891',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 600
                }}
              >
                Менеджер
              </div>
            </Col>
            <Col>
              <div
                className="text-end"
                style={{
                  color: '#31374A',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 700
                }}
              >
                {dataFromConfirmModal?.manager}
              </div>
            </Col>
          </Row>

          <Row className="" style={{ marginBottom: '12px' }}>
            <Col>
              <div
                style={{
                  color: '#6E7891',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 600
                }}
              >
                Покупатель
              </div>
            </Col>
            <Col>
              <div
                className="text-end"
                style={{
                  color: '#31374A',
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontWeight: 700
                }}
              >
                {dataFromConfirmModal?.buyer}
              </div>
            </Col>
          </Row>
        </div>

        <div className="text-center" style={{ marginBottom: '12px' }}>
          <div
            style={{
              color: '#003CC7',
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 600,
              marginBottom: '10px'
            }}
          >
            Сверьте фото оформителя с получателем заказа!
          </div>

          <Button onClick={onWatchPhotoBtnClick}>Посмотреть фото</Button>
        </div>
      </div>

      <Button onClick={handleOnClick} className="w-100" variant="secondary">
        {loading ? 'Загрузка' : 'Выдать заказ'}
      </Button>
    </ModalBase>
  );
};
