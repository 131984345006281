import { Container } from 'react-bootstrap';
import { Outlet, useNavigation } from 'react-router-dom';
import { Header } from './header';
import { Sidebar } from './sidebar';
import { Footer } from './footer';
import { Loader } from './loader';

const RootLayout = () => {
  const navigation = useNavigation();

  const isLoading = navigation.state === 'loading';

  return (
    <Container fluid className="px-0">
      {!isLoading && <Sidebar />}
      <Header />
      <div
        className={`content ${
          isLoading ? 'd-flex justify-content-center align-items-center' : ''
        }`}
      >
        {!isLoading && <Outlet />}
        {!isLoading && <Footer />}
        {isLoading && <Loader />}
      </div>
    </Container>
  );
};

export default RootLayout;
