import Logo from 'components/common/Logo';
import ThemeToggler from 'components/common/ThemeToggler';
import { PropsWithChildren } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';

interface AuthLayoutProps {
  logo?: boolean;
  className?: string;
}

const AuthLayout = ({
  logo = true // children
}: PropsWithChildren<AuthLayoutProps>) => {
  return (
    <Container fluid className="dark__bg-gray-1200">
      <div
        className="bg-holder bg-auth-card-overlay"
        style={{
          background:
            'linear-gradient(251.8deg, #F8EEFE -8.18%, #FEEEEE 99.03%)'
        }}
      />

      <Row className="flex-center position-relative min-vh-100 g-0 py-5">
        <ThemeToggler className="top-0 end-0 m-5 float-end w-auto position-absolute" />
        <Col xs={11} sm={10} xl={3}>
          <Card className="border border-translucent auth-card shadow p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Row className="align-items-center gx-0 gy-7">
                <Col className="mx-auto">
                  {logo && (
                    <div className="text-center">
                      <Link
                        to="/"
                        className="d-inline-block text-decoration-none mb-4"
                      >
                        <Logo
                          text={false}
                          width={68}
                          className="fw-bolder fs-5 d-inline-block"
                        />
                      </Link>
                    </div>
                  )}
                  <div className="auth-form-box">
                    <Outlet />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthLayout;
